import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { AccountStatus } from '@wilm/shared-types/account/Account';
import type { Order } from '@wilm/shared-types/cart';
import type { PaymentObject, Payment } from '@wilm/shared-types/cart/Payment';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import toast from 'react-hot-toast';
import DiscountErrorModal from 'components/commercetools-ui/organisms/checkout/components/dicount-codes-error-modal';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import ErrorModal from 'components/commercetools-ui/organisms/checkout/components/error-modal';
import { useResolveCardType } from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { useCurrencyMapperThresholdContext } from 'providers/currency-mapper-threshold';
import { sdk } from 'sdk';
import { useAccount, useCart, usePayment } from 'frontastic';
import type { TermsError } from 'frontastic/hooks/useCart/types';
import useStudioPaymentsSettings from 'frontastic/hooks/useStudioPaymentsSettings';
import type { PaymentData, PaymentProvider, CreditCardData, DeviceDataParams } from '../types';

interface Flex {
    microform(args: unknown): {
        createField: (
            type: string,
            args: unknown
        ) => {
            load: (selector: string) => void;
            on: (event: string, cb: (data: unknown) => void) => void;
        };
        createToken: (op: object, cb: createTokenCallback) => void;
    };
}

type createTokenCallback = (err: { reason: string; details: { location: string }[] }, token: string) => void;

declare global {
    interface Window {
        Flex: new (signature: string) => Flex;
    }
}

export const CybersourceContext = React.createContext<PaymentProvider>({} as PaymentProvider);

interface CybersourcePaymentProviderProps {
    children?: React.ReactNode;
}

const CybersourcePaymentProvider: React.FC<CybersourcePaymentProviderProps> = ({ children }) => {
    const { pathWithoutQuery } = usePath();
    const { orderCart, data: cartData, transaction } = useCart();
    const { mutateAccount, account } = useAccount();
    const router = useRouter();
    const cybersource = usePayment();
    const { currencyMapperThreshold } = useCurrencyMapperThresholdContext();

    const resolveCardType = useResolveCardType();

    const { paymentSettings, invoiceEnabled, creditCardEnabled, cardinalOrigin, tokenizeEarlyAndUseTokensOnly } =
        useStudioPaymentsSettings();
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

    const [paymentData, setPaymentData] = useState<PaymentData>({} as PaymentData);
    const [isLoading, setIsLoading] = useState(false);
    const [submitDataCollectionIframe, setSubmitDataCollectionIframe] = useState(false);

    const [submitStepUpIframe, setSubmitStepUpIframe] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const [processing, setProcessing] = useState(false);

    const [resetMicroform, setResetMicroform] = useState(false);

    const [selectedType, setSelectedType] = useSessionStorage<PaymentMethodType>('selectedPaymentMethod', PaymentMethodType.EMPTY);

    const [sameAsBillingAddress, setSameAsBillingAddress] = useSessionStorage<boolean>('sameAsBillingAddress', true);

    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<{ name: string; index: number }[]>([]);
    const [availableInstalmentOptions, setAvailableInstalmentOptions] = useState<number[]>([]);

    const defaultPaymentMethods = useRef([
        { name: PaymentMethodType.CARD, index: 0 },
        { name: PaymentMethodType.INVOICE, index: 1 }
    ]);
    const allowedAccountStatuses = useRef([AccountStatus.OPEN]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isDiscountErrorModalOpen, setIsDiscountErrorModalOpen] = useState(false);
    const [customError, setCustomError] = useState('');

    useEffect(() => {
        if (availablePaymentMethods.length > 0 && paymentSettings) {
            if (paymentSettings?.defaultPaymentMethod === PaymentMethodType.CARD && paymentSettings?.creditCardEnabled && !selectedType) {
                setSelectedType(PaymentMethodType.CARD);
                setPaymentData({ ...paymentData, type: PaymentMethodType.CARD } as PaymentData);
            } else if (
                paymentSettings?.defaultPaymentMethod === PaymentMethodType.INVOICE &&
                paymentSettings?.invoiceEnabled &&
                !selectedType &&
                availablePaymentMethods.map(pm => pm.name).includes(PaymentMethodType.INVOICE)
            ) {
                setSelectedType(PaymentMethodType.INVOICE);
                setPaymentData({ ...paymentData, type: PaymentMethodType.INVOICE } as PaymentData);
            } else if (
                selectedType &&
                (availablePaymentMethods.map(pm => pm.name).includes(selectedType) || selectedType === PaymentMethodType.INSTALMENT)
            ) {
                setPaymentData({ ...paymentData, type: selectedType } as PaymentData);
            } else {
                setSelectedType(availablePaymentMethods[0].name as PaymentMethodType);
                setPaymentData({ ...paymentData, type: availablePaymentMethods[0].name } as PaymentData);
            }
        }
    }, [paymentSettings, selectedType, availablePaymentMethods]);

    useEffect(() => {
        if (cartData && account && !availablePaymentMethods.length) {
            const accountStatus = account.statusOverride ?? account?.status;
            const allowedStatuses = accountStatus && allowedAccountStatuses.current.includes(accountStatus);
            if (allowedStatuses) {
                const lineItemInstalmentOptions =
                    !account?.isB2B && cartData.lineItems?.map(li => li.variant?.attributes?.instalmentOptions);

                if (lineItemInstalmentOptions && lineItemInstalmentOptions.length > 0) {
                    const lineItemCommonInstalmentOptions = lineItemInstalmentOptions?.reduce((arrayLeft, arrayRight) =>
                        arrayLeft?.filter((value: string) => arrayRight?.includes(value))
                    );

                    setAvailableInstalmentOptions(lineItemCommonInstalmentOptions);
                }
            }

            const commonPaymentMethods: { name: string; index: number }[] = [];

            const basketThreshold = currencyMapperThreshold[transaction.subtotal.currencyCode] ?? currencyMapperThreshold.default;
            defaultPaymentMethods.current.forEach(pm => {
                if (
                    pm.name === PaymentMethodType.CARD ||
                    (pm.name === PaymentMethodType.INVOICE &&
                        allowedStatuses &&
                        account?.isB2B &&
                        transaction.subtotal.centAmount >= basketThreshold * Math.pow(10, transaction.subtotal.fractionDigits))
                ) {
                    commonPaymentMethods.push(pm);
                }
            });

            setAvailablePaymentMethods(commonPaymentMethods);
        }
    }, [cartData, defaultPaymentMethods, account, currencyMapperThreshold]);

    async function tokenizeUserAndContinue(termsAccepted: boolean) {
        if (paymentData.type !== PaymentMethodType.CARD) return;
        setSubmitDataCollectionIframe(false);

        setIsLoading(true);
        const account = await cybersource.tokenizeUser(paymentData.token, paymentData.paymentId);

        if (account?.token?.paymentToken) {
            const newPaymentWithToken = await cybersource.addPaymentWithToken(transaction.total.centAmount, transaction.total.currencyCode);

            let deviceData: DeviceDataParams = {};
            if (
                newPaymentWithToken.paymentMethod === 'creditCardWithPayerAuthentication' &&
                newPaymentWithToken.deviceDataParams &&
                newPaymentWithToken.deviceDataParams.isv_requestJwt !== ''
            ) {
                const updatePayment = await cybersource.updatePaymentToTriggerAuthFlow(newPaymentWithToken.id);
                deviceData = updatePayment.deviceDataParams as DeviceDataParams;
                setSubmitDataCollectionIframe(true);
            } else {
                await makeTransactionAndOrder(termsAccepted, newPaymentWithToken.id);
            }
            setPaymentData({
                ...paymentData,
                paymentId: newPaymentWithToken.id,
                maskedCard: newPaymentWithToken.maskedCard,
                expiryMonth: newPaymentWithToken.expiryMonth,
                expiryYear: newPaymentWithToken.expiryYear,
                paymentMethod: newPaymentWithToken.paymentMethod,
                deviceDataParams: deviceData,
                paymentCardType: resolveCardType(newPaymentWithToken.maskedCard),
                withToken: true
            } as PaymentData);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setIsErrorModalOpen(true);
            setCustomError(
                `${formatCheckoutMessage({
                    id: 'customer.tokenization.failed',
                    defaultMessage:
                        'We were unable to process your payment. Please double-check the credit card and address information and try again'
                })}`
            );
            getBackAndResetMicroform();
        }
    }

    async function updatePaymentWithTransientToken() {
        if (paymentData.type !== PaymentMethodType.CARD) return;
        setSubmitDataCollectionIframe(false);
        const payment = await cybersource.paymentUpdateWithTransientToken(paymentData.token, paymentData.paymentId);

        if (payment) {
            let deviceData: DeviceDataParams = {};
            if (
                payment.paymentMethod === 'creditCardWithPayerAuthentication' &&
                payment.deviceDataParams &&
                payment.deviceDataParams.isv_requestJwt !== ''
            ) {
                deviceData = payment.deviceDataParams as DeviceDataParams;
            }
            setPaymentData({
                ...paymentData,
                tokenizeCard: true,
                maskedCard: payment.maskedCard,
                paymentMethod: payment.paymentMethod,
                deviceDataParams: deviceData
            } as CreditCardData);
            setIsLoading(false);
            setResetMicroform(false);
        } else {
            setIsErrorModalOpen(true);
            setCustomError(
                formatCheckoutMessage({
                    id: 'payment.failed.device.data.not.received',
                    defaultMessage: 'Device data request parameters not received'
                })
            );
            getBackAndResetMicroform();

            return;
        }
        return payment;
    }

    useEffect(() => {
        if (submitDataCollectionIframe) {
            window.addEventListener('message', deviceDataCollectionResponseHandler, false);

            const cardinalCollectionForm = document.querySelector<HTMLFormElement>('#cardinal_collection_form')!;
            if (cardinalCollectionForm) {
                cardinalCollectionForm.submit();
                setIsLoading(true);
            }
        }

        return () => {
            window.removeEventListener('message', deviceDataCollectionResponseHandler, false);
        };
    }, [submitDataCollectionIframe]);

    function deviceDataCollectionResponseHandler(event: { origin: string; data: string }) {
        (async () => {
            console.log(cardinalOrigin, ' co');
            if (paymentData.type !== PaymentMethodType.CARD) return;
            if (event.origin === cardinalOrigin) {
                console.log(event.data);
                if (event.data) {
                    const paymentUpdate = await cybersource.updatePaymentWithClientUserData(paymentData.paymentId);

                    if (paymentUpdate) {
                        await processPaymentEnrollmentData(paymentUpdate);
                    } else {
                        getBackAndResetMicroform();
                        setIsErrorModalOpen(true);
                        setCustomError(
                            formatCheckoutMessage({
                                id: 'payment.failed.client.user.data',
                                defaultMessage: 'Authorization timed out please try again later or use another card'
                            })
                        );
                        setIsLoading(false);

                        return;
                    }
                    window.removeEventListener('message', deviceDataCollectionResponseHandler, false);
                }
            }
        })();
    }

    useEffect(() => {
        if (paymentData.type !== PaymentMethodType.CARD) return;
        if (submitStepUpIframe && paymentData.paymentEnrollmentData.isv_stepUpUrl && paymentData.paymentEnrollmentData.isv_responseJwt) {
            const stepUpForm = document.querySelector<HTMLFormElement>('#step-up-form')!;
            if (stepUpForm) {
                stepUpForm.submit();
                window.addEventListener('message', consumeTransactionResponseHandler, false);
            }
        }

        return () => {
            window.removeEventListener('message', consumeTransactionResponseHandler, false);
        };
    }, [submitStepUpIframe, paymentData]);

    function consumeTransactionResponseHandler(event: { origin: string; data: string }) {
        (async () => {
            if (paymentData.type !== PaymentMethodType.CARD) return;

            if (event.origin === window.location.origin && typeof event.data === 'string') {
                const pspResponse = JSON.parse(event.data);
                const stepUpIframe = document.getElementsByName('step-up-iframe');
                if (stepUpIframe && stepUpIframe.length > 0 && pspResponse?.transactionId) {
                    setIsLoading(true);
                    const paymentUpdate = await cybersource.updateWithThreeDsTransactionId(
                        pspResponse.transactionId,
                        paymentData.paymentId
                    );

                    if (paymentUpdate.payment && !paymentUpdate.error) {
                        setSubmitStepUpIframe(false);

                        await processPaymentEnrollmentData(paymentUpdate.payment);
                    } else if (paymentUpdate.error && paymentUpdate.errorMsg === 'DuplicatedPayments') {
                        setIsLoading(false);
                        setSubmitStepUpIframe(false);
                        setIsErrorModalOpen(true);
                        setCustomError(
                            formatCheckoutMessage({
                                id: 'payment.already.processed',
                                defaultMessage:
                                    'Payment for this basket has already been processed. Please contact support for further assistance.'
                            })
                        );
                    }
                    window.removeEventListener('message', consumeTransactionResponseHandler, false);
                }
            }
        })();
    }

    const getBackAndResetMicroform = () => {
        setResetMicroform(true);
        setIsLoading(false);
        setProcessing(false);
        setSubmitDataCollectionIframe(false);
        router.push(`${pathWithoutQuery}?step=1`);
    };

    const processPaymentEnrollmentData = async (payment: PaymentObject) => {
        const paymentEnrollmentData = payment.payerEnrollmentData;
        if (paymentEnrollmentData.isv_payerEnrollHttpCode === 201 && paymentEnrollmentData.isv_payerEnrollStatus === 'AUTHORIZED') {
            console.log('Direct Authorized no Step up');
            await makeTransactionAndOrder(isTermsAccepted);
        } else if (
            paymentEnrollmentData.isv_payerEnrollHttpCode === 201 &&
            paymentEnrollmentData.isv_payerEnrollStatus === 'CUSTOMER_AUTHENTICATION_REQUIRED'
        ) {
            console.log('need step from 6');
            getBackAndResetMicroform();
        } else if (paymentEnrollmentData.isv_payerEnrollHttpCode === 201 && paymentEnrollmentData.isv_payerEnrollStatus === 'DECLINED') {
            console.log('card declined');
            setIsErrorModalOpen(true);
            setCustomError(
                formatCheckoutMessage({
                    id: 'payment.failed.card.declined',
                    defaultMessage: 'Your card was declined please try again or use another card'
                })
            );

            getBackAndResetMicroform();
            return;
        } else if (paymentEnrollmentData.isv_payerEnrollHttpCode === 400) {
            setIsErrorModalOpen(true);
            setCustomError(
                formatCheckoutMessage({
                    id: 'payment.failed.card.error',
                    defaultMessage: 'Payment can not be processed'
                })
            );
            getBackAndResetMicroform();
        } else if (paymentEnrollmentData.isv_payerAuthenticationRequired === true) {
            console.log('need step up');

            setSubmitStepUpIframe(false);
            setPaymentData({ ...paymentData, paymentEnrollmentData } as CreditCardData);
            setIsLoading(false);

            setSubmitStepUpIframe(true);
        }
    };

    const makeTransactionAndOrder = useCallback(
        async (isTermsAccepted: boolean, paymentId?: string) => {
            if (paymentData.type !== PaymentMethodType.CARD) return;
            const paymentResponse = await sdk.callAction<{ payment: Payment; continueWithError: boolean }>({
                actionName: 'cart/addPaymentTransaction',
                payload: {
                    paymentId: paymentId ?? paymentData.paymentId,
                    tokenizeCard: paymentData?.tokenizeCard,
                    token: paymentData?.token
                }
            });

            const payment = (paymentResponse.isError ? null : paymentResponse.data.payment) as PaymentObject | null;

            if (
                !(paymentResponse as any).data.continueWithError &&
                (paymentResponse.isError || payment?.transactionStatus.toLowerCase() !== 'success')
            ) {
                getBackAndResetMicroform();

                setIsErrorModalOpen(true);
                setCustomError(
                    formatCheckoutMessage({
                        id: 'payment.failed',
                        defaultMessage: 'We could not process your payment, please try again later.'
                    })
                );
                return;
            }
            const response = await orderCart(isTermsAccepted);
            const termsError = response as TermsError;
            if (termsError?.error?.message === 'termsNotAccepted') {
                toast.error(
                    formatCheckoutMessage({
                        id: 'terms.not.accepted',
                        defaultMessage: 'Please accept Terms and Conditions to proceed.'
                    })
                );
                return;
            }

            if (termsError?.error?.message === 'Error: discountCodesNotAPpplicable') {
                setIsDiscountErrorModalOpen(true);
                return;
            }

            if (termsError?.isError) {
                toast.error(
                    formatCheckoutMessage({
                        id: 'order.failed',
                        defaultMessage: 'We could not process your order, please try again later.'
                    })
                );
                return;
            }
            const order = response as Order;
            onSuccessfulPayment(order);
            await mutateAccount();
            setProcessing(false);
            setIsLoading(false);
        },
        [paymentData, isTermsAccepted]
    );

    const makePayment = useCallback(
        async (isTermsAccepted: boolean, isAsianMidCurrency: boolean) => {
            if (paymentData.type !== PaymentMethodType.CARD) return;
            setIsTermsAccepted(isTermsAccepted);

            if (!paymentData.withToken && tokenizeEarlyAndUseTokensOnly && !isAsianMidCurrency) {
                await tokenizeUserAndContinue(isTermsAccepted);
            } else {
                if (!paymentData.withToken) {
                    await updatePaymentWithTransientToken();
                }
                if (paymentData.paymentMethod == 'creditCardWithPayerAuthentication') {
                    setSubmitDataCollectionIframe(true);
                } else {
                    await makeTransactionAndOrder(isTermsAccepted);
                }
            }
        },
        [paymentData]
    );

    const onSuccessfulPayment = useCallback(
        (order: Order) => {
            track({ type: TagsActionType.PURCHASE, payload: { order: order, discountCodes: cartData?.discountCodes ?? [] } });
            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem('lastPlacedOrder', JSON.stringify(order));
            }
            void sdk.callAction({ actionName: 'cart/resetCart' });
            router.push('/thank-you');
        },
        [router, cartData?.discountCodes]
    );

    const [tokenSignature, setTokenSignature] = useState('');
    const [microform, setMicroform] = useState<{
        createToken: (op: object, cb: createTokenCallback) => void;
    } | null>(null);
    const [numberField, setNumberField] = useState<{ on: (event: string, cb: (data: unknown) => void) => void } | null>(null);
    const [cardType, setCardType] = useState('');

    useEffect(() => {
        const numberChangeListener = (data: any) => {
            if (data?.card?.length) {
                setCardType(data.card[0].name);
            }
        };
        if (numberField) {
            numberField.on('change', numberChangeListener);
        }
    }, [numberField]);

    const createPaymentToGetTokenContext = useCallback(
        async (onInvalid: () => void, renew = false, currency = null) => {
            setSubmitDataCollectionIframe(false);
            if (!tokenSignature || renew) {
                const payment = await cybersource.addInitialPaymentToGetTokenContext(
                    transaction.total.centAmount,
                    currency ?? transaction.total.currencyCode
                );

                if (payment) {
                    if (!payment.signature) {
                        setIsErrorModalOpen(true);
                        setCustomError('');
                    }
                    setTokenSignature(payment.signature);
                    setupMicroform(payment.signature);
                    setPaymentData({
                        ...paymentData,
                        paymentId: payment.id,
                        type: PaymentMethodType.CARD,
                        paymentMethod: payment.paymentMethod
                    } as CreditCardData);
                } else {
                    onInvalid();
                    setIsErrorModalOpen(true);
                    setCustomError('');
                }
            } else {
                setupMicroform(tokenSignature);
            }
        },
        [tokenSignature, resetMicroform, paymentData, transaction.total.centAmount, transaction.total.currencyCode]
    );

    function setupMicroform(signature: string) {
        const myStyles = {
            input: {
                'font-size': '14px',
                'font-family': 'helvetica, tahoma, calibri, sans-serif',
                color: '#555'
            },
            ':focus': { color: 'blue' },
            ':disabled': { cursor: 'not-allowed' },
            '::placeholder': {
                color: '#414141'
            },
            valid: { color: '#3c763d' },
            invalid: { color: '#a94442' }
        };

        const flex = new window.Flex(signature);

        const microform = flex.microform({ styles: myStyles });
        const number = microform.createField('number', { placeholder: 'Card Number', id: 'cs-form-number-container' });
        const securityCode = microform.createField('securityCode', { placeholder: '***' });

        number.load('#number-container');
        securityCode.load('#securityCode-container');
        setMicroform(microform);
        setNumberField(number);
    }

    const iframes = () => {
        if (paymentData.type !== PaymentMethodType.CARD) return <></>;
        return (
            <>
                <div>
                    <iframe
                        id="cardinal_collection_iframe"
                        name="collectionIframe"
                        title="collectionIframe"
                        height="10"
                        width="10"
                        style={{ display: 'none' }}
                    ></iframe>
                    <form
                        id="cardinal_collection_form"
                        method="POST"
                        target="collectionIframe"
                        action={paymentData?.deviceDataParams?.isv_deviceDataCollectionUrl ?? ''}
                    >
                        <input
                            id="cardinal_collection_form_input"
                            type="hidden"
                            name="JWT"
                            value={paymentData?.deviceDataParams?.isv_requestJwt ?? ''}
                        ></input>
                    </form>
                </div>
                <div>
                    <iframe
                        name="step-up-iframe"
                        title="step-up-iframe"
                        height="600"
                        width="400"
                        className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white"
                        style={{ display: submitStepUpIframe ? 'block' : 'none' }}
                    ></iframe>
                    <form
                        id="step-up-form"
                        target="step-up-iframe"
                        method="post"
                        action={paymentData?.paymentEnrollmentData?.isv_stepUpUrl ?? ''}
                    >
                        {' '}
                        <input type="hidden" name="JWT" value={paymentData?.paymentEnrollmentData?.isv_responseJwt ?? ''} />
                    </form>
                    {submitStepUpIframe && <Overlay />}
                </div>
            </>
        );
    };
    const loader = useCallback(() => {
        return (
            <>
                <Overlay />
                <div
                    style={{ width: '50px', height: '50px' }}
                    className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white"
                >
                    <div className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white">
                        <LoadingIcon className="fill-gray-700" />
                    </div>
                </div>
            </>
        );
    }, []);

    const value = useMemo(() => {
        return {
            creditCardEnabled,
            invoiceEnabled,
            selectedType,
            paymentData,
            processing,
            cardinalOrigin,
            sameAsBillingAddress,
            setSameAsBillingAddress,
            setProcessing,
            setSelectedType,
            setPaymentData,
            setSubmitDataCollectionIframe,
            makeInvoicePayment: cybersource.makeInvoicePayment,
            makeInstalmentPayment: cybersource.makeInstalmentPayment,
            addInitialPaymentToGetTokenContext: cybersource.addInitialPaymentToGetTokenContext,
            addPaymentWithToken: cybersource.addPaymentWithToken,
            updatePaymentToTriggerAuthFlow: cybersource.updatePaymentToTriggerAuthFlow,
            makePayment,
            setResetMicroform,
            resetMicroform,
            onSuccessfulPayment,
            availablePaymentMethods,
            availableInstalmentOptions,
            createPaymentToGetTokenContext,
            microform,
            cardType
        };
    }, [
        creditCardEnabled,
        invoiceEnabled,
        selectedType,
        paymentData,
        processing,
        cardinalOrigin,
        sameAsBillingAddress,
        setSameAsBillingAddress,
        setProcessing,
        setSelectedType,
        setPaymentData,
        setSubmitDataCollectionIframe,
        cybersource,
        makePayment,
        setResetMicroform,
        resetMicroform,
        onSuccessfulPayment,
        availablePaymentMethods,
        availableInstalmentOptions,
        createPaymentToGetTokenContext,
        microform,
        cardType
    ]);

    return (
        <CybersourceContext.Provider value={value}>
            {iframes()}
            {isLoading && loader()}
            {children}

            <ErrorModal isErrorModalOpen={isErrorModalOpen} setIsErrorModalOpen={setIsErrorModalOpen} customError={customError} />
            <DiscountErrorModal isDiscountErrorModalOpen={isDiscountErrorModalOpen} />
        </CybersourceContext.Provider>
    );
};

export default CybersourcePaymentProvider;
